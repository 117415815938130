<template>
  <div>
    <ErrorPage v-if="showError" />
    <div class="w-full h-full pt-5" v-else>
      <loader size="xxs" :loader-image="false" v-if="isLoading" />
      <template v-else>
        <div class="flex mb-10 px-5">
          <h1 class="text-xl text-left font-extrabold mr-5">Organisation</h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
        <div class="flex flex-col gap-5">
          <div class="mx-5 rounded-md flex justify-between gap-5">
            <card
              class="flex flex-col justify-start items-start p-5 gap-5 w-3/5"
            >
              <div class="w-full flex flex-col gap-3 relative">
                <div class="flex flex-col justify-start items-start gap-0">
                  <p class="font-bold text-darkPurple text-lg">
                    Geographic Distribution
                  </p>
                </div>
                <template
                  v-if="locationDistribution.length || isLocationLoading"
                >
                  <!-- <div class="flex justify-between gap-5 relative">
                    <card class="flex justify-evenly items-center gap-1 px-5 h-11 relative">
                      <div class="w-1/7 pr-2 border-r border-darkPurple">
                        <Icon icon-name="calendar" size="xs" />
                      </div>
                      <div class="w-5/7 flex">
                        <datepicker placeholder="Year to Date" input-class="w-32 pl-2" />
                      </div>
                      <div class="w-1/7">
                        <Icon icon-name="caret-down" size="xs" />
                      </div>
                    </card>
                  </div> -->
                  <template v-if="isLocationLoading">
                    <div style="height: 300px">
                      <loader size="xxs" :loader-image="false" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="relative" style="width: 100%; height: 100%">
                      <GoogleMap
                        styles="width:100%;height:400px"
                        :locations="locationDistribution"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="flex flex-col text-center mb-3">
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize"
                      style="width: 350px; height: 350px; margin: 0 auto"
                    />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                </template>
              </div>
            </card>
            <card class="w-2/5 flex flex-col p-5 gap-7">
              <div class="text-darkPurple flex flex-col gap-5 w-full">
                <p class="font-bold text-lg">Organisation Hierarchy</p>
                <div class="w-full flex justify-between item-center">
                  <p class="font-bold text-lg text-darkPurple">Total</p>
                  <p class="font-extrabold text-3xl">
                    {{ heirarchy.hierarchyCount }}
                  </p>
                </div>
              </div>
              <template>
                <template
                  v-if="heirarchy.hierarchies && heirarchy.hierarchies.length"
                >
                  <div
                    class="bg-gray-50 rounded-md p-2 flex flex-col gap-8 relative overflow-y-auto h-96"
                  >
                    <div
                      v-for="heirarchyItem in heirarchy.hierarchies"
                      :key="heirarchyItem.id"
                    >
                      <div class="flex justify-between items-center gap-2">
                        <p class="text-darkPurple text-base font-semibold">
                          {{ heirarchyItem.name }}
                        </p>
                        <p class="text-romanSilver text-base font-normal">
                          {{ heirarchyItem.functionCount }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="flex flex-col text-center mb-3">
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize"
                      style="width: 100%; height: 100%; margin: 0 auto"
                    />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                </template>
              </template>
            </card>
          </div>
          <div class="mx-5 flex justify-between gap-5">
            <card
              class="p-5 flex flex-col justify-between items-start gap-5 w-full"
            >
              <div class="w-full flex flex-col gap-3">
                <div class="flex justify-between items-center gap-0">
                  <p class="font-bold text-darkPurple text-lg">Designations</p>
                  <card
                    class="w-44 h-11 px-3 flex items-center text-base text-darkPurple"
                  >
                    <c-select
                      class="w-full select-css"
                      variant="rm-border"
                      placeholder="All"
                      v-model="functionDistributionId"
                      :options="['All', 'By Functions', 'By Job Levels']"
                      @input="onFilterDesignationsBySelector($event)"
                    />
                  </card>
                </div>
                <template>
                  <template
                    v-if="
                      designationChartData.labels &&
                      designationChartData.labels.length
                    "
                  >
                    <div class="flex flex-col justify-end gap-2">
                      <template v-if="isDesignationLoading">
                        <div style="height: 300px">
                          <loader size="xxs" :loader-image="false" />
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex justify-center items-start">
                          <DoughnutChart
                            :options="designationChartOptions"
                            :chart-data="designationChartData"
                            :value="designationChartData.totalEmployees"
                            :data="designationChartData"
                            :text-height="2.5"
                            :styles="{
                              height: '500px',
                              width: '500px',
                              position: 'relative',
                            }"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex flex-col text-center mb-3">
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width: 100%; height: 100%; margin: 0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">
                        There is no data to show currently.
                      </p>
                    </div>
                  </template>
                </template>
              </div>
            </card>
            <card
              class="p-5 flex flex-col justify-between items-start gap-5 w-full"
            >
              <div class="w-full flex flex-col gap-3">
                <div class="flex flex-col justify-between items-start gap-0">
                  <p class="font-bold text-darkPurple text-lg">
                    Job Levels (By Job Category)
                  </p>
                  <template v-if="jobLevelChartData">
                    <div
                      class="w-full flex flex-col justify-center items-center gap-5 py-5 px-2"
                    >
                      <div style="height: 300px" v-if="isJobLevelLoading">
                        <loader size="xxs" :loader-image="false" />
                      </div>
                      <template v-if="!isJobLevelLoading">
                        <HorizontalBarChart
                          :options="jobLevelChartOptions"
                          :chart-data="jobLevelChartData"
                          :data="jobLevelChartData"
                          :styles="{
                            height: '320px',
                            width: '100%',
                            position: 'relative',
                          }"
                        />
                        <div
                          class="flex flex-wrap justify-center items-center gap-3 px-10"
                        >
                          <div
                            class="flex justify-center items-center gap-1"
                            v-for="legend in jobLevelChartData.legends"
                            :key="legend.label"
                          >
                            <div
                              class="rounded-full w-3 h-3"
                              :style="`background: ${legend.bgColor}`"
                            />
                            <p class="text-xs text-jet">{{ legend.label }}</p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="flex flex-col justify-center items-center text-center w-full h-full"
                    >
                      <Icon
                        icon-name="dashboard-error"
                        style="width: 100%; height: 100%; margin: 0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">
                        There is no data to show currently.
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </card>
          </div>
          <template>
            <card
              class="mx-5 p-5 flex flex-col justify-between items-start gap-5"
            >
              <p class="font-bold text-darkPurple text-lg">Status</p>
              <div class="w-full flex justify-between items-center">
                <!-- <template v-if="employeeStatChartData.labels">
                <div class="flex justify-center items-center bg-ghostWhite px-2 rounded h-10">
                  <SearchInput placeholder="Search" class="-mt-2" />
                  <Icon class-name="text-darkPurple" size="xs" icon-name="sort_size" />
                </div>
              </template> -->
                <div class="w-full flex justify-end">
                  <card
                    class="w-44 h-11 px-3 flex items-center text-base text-darkPurple"
                  >
                    <c-select
                      class="w-full select-css"
                      variant="rm-border"
                      placeholder="Status"
                      v-model="selectedEmployeeStat"
                      @input="orgEmployeeStatDistribution($event)"
                      :options="['Status', 'Role', 'User Profile']"
                    />
                  </card>
                </div>
              </div>
              <template
                v-if="
                  employeeStatChartData.labels &&
                  employeeStatChartData.labels.length
                "
              >
                <div
                  class="w-full flex flex-col justify-center items-center gap-5 py-5 px-2"
                >
                  <div style="height: 300px" v-if="isEmployeeStatLoading">
                    <loader size="xxs" :loader-image="false" />
                  </div>
                  <template v-else>
                    <HorizontalBarChart
                      :options="employeeStatChartOptions"
                      :chart-data="employeeStatChartData"
                      :data="employeeStatChartData"
                      :styles="{
                        height: '120px',
                        width: '100%',
                        position: 'relative',
                      }"
                    />
                    <div
                      class="flex flex-wrap justify-center items-center gap-3 px-10"
                    >
                      <div
                        class="flex justify-center items-center gap-1"
                        v-for="legend in employeeStatChartData.legends"
                        :key="legend.label"
                      >
                        <div
                          class="rounded-full w-3 h-3"
                          :style="`background: ${legend.bgColor}`"
                        />
                        <p class="text-xs text-jet">{{ legend.label }}</p>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div
                  class="flex flex-col justify-center items-center text-center w-full h-full"
                >
                  <Icon
                    icon-name="dashboard-error"
                    style="width: 200px; height: 200px"
                  />
                  <p class="text-xl font-bold">No Data Available</p>
                  <p class="text-xs text-romanSilver">
                    There is no data to show currently.
                  </p>
                </div>
              </template>
            </card>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { startCase } from "lodash";
// import Datepicker from "vuejs-datepicker"
import Gradient from "javascript-color-gradient";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
// import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input"
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import DoughnutChart from "@/components/donutChart";
import HorizontalBarChart from "@/components/HorizontalBarChart";
import Icon from "@/components/Icon";
import GoogleMap from "@/components/GoogleMap";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    // Datepicker,
    Breadcrumb,
    HorizontalBarChart,
    DoughnutChart,
    // SearchInput,
    CSelect,
    ErrorPage,
    Card,
    Icon,
    GoogleMap,
  },
  data() {
    return {
      isLoading: true,
      showError: false,
      isJobLevelLoading: false,
      isEmployeeStatLoading: false,
      isLocationLoading: false,
      isDesignationLoading: false,
      selectedEmployeeStat: "Status",
      heirarchy: {},
      designationOptions: [],
      locationDistribution: [],
      locationDistributionId: "",
      functionDistributionId: "",
      jobLevelChartData: {},
      employeeStatChartData: {},
      designationChartData: {},
      jobLevelChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "bottom",
          labels: { usePointStyle: true, fontSize: 9, fontColor: "#333333" },
        },
        scales: {
          yAxes: [{ gridLines: { display: false } }],
          xAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: {
                display: true,
                color: "#878E99",
                borderDash: [4, 4],
              },
            },
          ],
        },
      },
      employeeStatChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "bottom",
          labels: { usePointStyle: true, fontSize: 9, fontColor: "#333333" },
        },
        scales: {
          yAxes: [{ gridLines: { display: false } }],
          xAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: {
                display: true,
                color: "#878E99",
                borderDash: [4, 4],
              },
            },
          ],
        },
      },
      designationChartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: 60,
        plugins: {
          labels: {
            render: "percentage",
            fontColor: "red",
            position: "outside",
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: { usePointStyle: true, fontSize: 10, fontColor: "#321C3B" },
        },
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Analytics",
          href: "Analytics",
          id: "analytics",
        },
        {
          disabled: false,
          text: "Organisation",
          href: "OrganisationAnalytics",
          id: "OrganisationAnalytics",
        },
      ],
    };
  },
  methods: {
    getLocationDistributions({ locationId }) {
      if (locationId === "All Locations") locationId = "";
      this.isLocationLoading = true;
      this.$_getOrgLocationDistribution({ locationId }).then(({ data }) => {
        this.locationDistribution = data.locations;
        this.isLocationLoading = false;
      });
    },
    async getOrganisationHierarchy() {
      await this.$_getOrganisationHierarchy().then(({ data }) => {
        this.heirarchy = data;
      });
    },
    async onFilterDesignationsBySelector(filterBy) {
      this.isDesignationLoading = true;
      const payload = { labels: [], datasets: [], totalEmployees: 0 };
      let params = "?type=";

      if (filterBy === "By Job Levels") {
        params = "?type=levels";
      }

      if (filterBy === "By Functions") {
        params = "?type=functions";
      }
      await this.$_getOrgDesignationDistribution(params).then(({ data }) => {
        payload.labels = data.designations.map((item) => item.name);
        payload.datasets = data.designations.map((item) => item.count);
        payload.totalEmployees = data.totalDesignations;
      });

      const COLORS = new Gradient()
        .setColorGradient(
          "#13B56A",
          "#2176FF",
          "#EA3C53",
          "#878E99",
          "#F15A29",
          "#00D5E0"
        )
        .setMidpoint(payload.labels?.length)
        .getColors();

      this.designationChartData = {
        labels: payload.labels,
        totalEmployees: payload.totalEmployees,
        datasets: [{ data: payload.datasets, backgroundColor: COLORS }],
      };
      this.isDesignationLoading = false;
    },
    async getOrgJobCategoryDistribution() {
      this.isJobLevelLoading = true;
      const jobLevelLabels = [];
      const jobLevelData = [];
      const distJobLevels = await this.$_getOrgJobLevelDistribution();
      const jobLevels = await this.$_getLevels("");

      Promise.allSettled([distJobLevels, jobLevels]).then(
        ([distResponse, levelResponse]) => {
          const distJobLevelValues = distResponse?.value?.data?.data;
          const jobLevelValues = levelResponse?.value?.data?.levels;

          const mergedLevelData = distJobLevelValues.map((item, index) => ({
            ...Object.assign(item, jobLevelValues[index]),
            categoryName: item.category.name?.split(" "),
          }));

          mergedLevelData?.forEach((jobLevel) => {
            jobLevelLabels.push(jobLevel.categoryName);
            jobLevelData.push(jobLevel.count);
          });

          const COLORS = new Gradient()
            .setColorGradient(
              "#13B56A",
              "#2176FF",
              "#EA3C53",
              "#878E99",
              "#F15A29",
              "#00D5E0"
            )
            .setMidpoint(mergedLevelData?.length)
            .getColors();
          const legendsArray = jobLevelLabels?.map((legend, i) => ({
            label: startCase(legend),
            bgColor: COLORS[i],
          }));

          this.jobLevelChartData = {
            labels: jobLevelLabels,
            legends: legendsArray,
            datasets: [{ data: jobLevelData, backgroundColor: COLORS }],
          };

          this.isJobLevelLoading = false;
        }
      );
    },
    async orgEmployeeStatDistribution(filterBy) {
      this.isEmployeeStatLoading = true;

      const FILTER_BY_STATUS = "Status";
      const FILTER_BY_ROLE = "Role";
      const FILTER_BY_USER_PROFILES = "User Profile";

      if (filterBy === FILTER_BY_STATUS) {
        await this.$_getOrgEmployeeStatusDistribution().then(({ data }) => {
          if (Object.values(data.data).length) {
            const COLORS = ["#13B56A", "#2176FF"];
            const employeeStatLabels = ["Active", "Inactive"];
            const employeeStatLegends = employeeStatLabels?.map(
              (legend, i) => ({
                label: startCase(legend),
                bgColor: COLORS[i],
              })
            );
            const employeeStatData = [
              data.data?.activeEmployee || 0,
              data.data?.inactiveEmployee || 0,
            ];

            this.employeeStatChartData = {
              labels: employeeStatLabels,
              legends: employeeStatLegends,
              datasets: [{ data: employeeStatData, backgroundColor: COLORS }],
            };
          }
        });
      } else if (filterBy === FILTER_BY_ROLE) {
        await this.$_getOrgAdminDistribution().then(({ data }) => {
          if (Object.values(data.data).length) {
            const COLORS = ["#13B56A", "#2176FF"];
            const employeeStatLabels = ["Admins", "Non-Admins"];
            const employeeStatLegends = employeeStatLabels?.map(
              (legend, i) => ({
                label: startCase(legend),
                bgColor: COLORS[i],
              })
            );
            const employeeStatData = [
              data.data?.isAdmin || 0,
              data.data?.nonAdmin || 0,
            ];

            this.employeeStatChartData = {
              labels: employeeStatLabels,
              legends: employeeStatLegends,
              datasets: [{ data: employeeStatData, backgroundColor: COLORS }],
            };
          }
        });
      } else if (filterBy === FILTER_BY_USER_PROFILES) {
        await this.$_getOrgEmployeeStatusDistribution().then(({ data }) => {
          if (Object.values(data.data).length) {
            const COLORS = ["#13B56A", "#2176FF"];
            const employeeStatLabels = ["Employees", "Non-Employees"];
            const employeeStatLegends = employeeStatLabels?.map(
              (legend, i) => ({
                label: startCase(legend),
                bgColor: COLORS[i],
              })
            );
            const employeeStatData = [
              data.data?.activeEmployee || 0,
              data.data?.inactiveEmployee || 0,
            ];

            this.employeeStatChartData = {
              labels: employeeStatLabels,
              legends: employeeStatLegends,
              datasets: [{ data: employeeStatData, backgroundColor: COLORS }],
            };
          }
        });
      }

      this.isEmployeeStatLoading = false;
    },
    async bootstrapModule() {
      this.getOrganisationHierarchy();
      this.orgEmployeeStatDistribution("Status");
      this.onFilterDesignationsBySelector("All");
      this.getLocationDistributions({});
      this.getOrgJobCategoryDistribution();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$handlePrivilege("analyticsOrganization", "viewOrganizationAnalytics");
      this.showError = false;
      await this.bootstrapModule();
      this.isLoading = false;
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .rm-border {
  border: none !important;
}
::v-deep .select-class {
  display: flex;
  justify-content: space-between;
}
::v-deep .vdp-datepicker__calendar {
  margin-left: -100px !important;
  color: #321c3b !important;
}
::v-deep .vdp-datepicker {
  color: #321c3b !important;
  input[type="text"] {
    color: #321c3b !important;
    &:hover,
    &:focus {
      outline: none !important;
      color: #321c3b !important;
    }
  }
}
::v-deep .select-css {
  justify-content: flex-end;
}
::v-deep .select-css span {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner-donut-function {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 232px;
  top: -35px;
}
.inner-donut-gender {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 212px;
  top: -15px;
}
.color-white {
  color: white !important;
}
</style>
